import { createStore } from 'vuex'
import requests from '../plugins/requests'

export default createStore({
  state(){
    return {
      user: {
        name: '',
        password: ''
      },
      isAuthenticated: false,
    }
  },
  getters:{
    authenticated: (state) => state.isAuthenticated,
  },
  mutations: {
    authenticate(state, input){
      requests.auth(input).then(res => {
        if (res.status == 200){
          const user = JSON.parse(res.config.data)
          console.log(user)
          state.user.name = user.username
          state.user.password = user.password
          state.isAuthenticated = true
        }
          
      }).catch(e => console.log(e))
    },
  },
  actions: {
    authenticate: ({ commit }, user, password) => commit('authenticate', user, password),
  },
  modules: {
  }
})
