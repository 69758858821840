<template>
<body class="">
    <div class="card text-start position-absolute top-50 start-50 translate-middle" style="width: 20rem;">

  <div class="card-body">
    <h1 class="card-title mb-3">Login</h1>
    <form class="form">
  <div class="form-group">
    <label for="exampleInputEmail1">Usuário</label>
    <input ref="email" type="text" class="form-control" id="exampleInputEmail1" placeholder="Entre com o usuário">
  </div>
  <div class="form-group mt-3">
    <label for="exampleInputPassword1">Senha</label>
    <input ref="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="Senha">
  </div>
  <button @click.prevent="login()" type="submit" class="btn btn-primary mt-3">Entrar</button>
</form>
  </div>
</div>

</body>
</template>

<script>
export default {
      methods: {
    login () {
      const { email, password } = this.$refs
      this.$store.dispatch({
        type: 'authenticate',
        user: email.value,
        password: password.value
      })
    }
  }
}
</script>
