import { http } from './axios'

export default	{

    auth: (data) => {
        const { password, user } = data
		return http.post('admin/authenticate',{ 
            username: user, 
            password
            }
            ).catch(e => console.log(e))
    },

    users: () => { return http.get('users/all') },

    user: (user) => { return http.get('users/findById', { params: { id: user } }) },
    
    getByUserGroup: (group) => { return http.get('users/getByUserGroup', { params: { userGroup: group } }) },

    groups: () => { return http.get('user_group/all') },


}
