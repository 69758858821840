<template>
  <span v-if="store.authenticated">
  <navbar/>
  <router-view/>
  </span>
  <login v-else />
</template>

<script>
import navbar from './components/TheNavbar'
import login from './views/Login'

export default {
  data(){
    return {
      store: this.$store.getters
    }
  },
  components:{
    navbar,
    login
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
